import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languageCode
            siteUrl
            title
            description
            author
            keywords
            socialHandles {
              twitter
            }
          }
        }
      }
    `
  );

  const {
    languageCode,
    siteUrl,
    title,
    author,
    description,
    keywords,
    socialHandles,
  } = siteMetadata;

  const twitterBanner = '/twitter.png';
  const facebookBanner = '/facebook.png';

  return (
    <Helmet
      title={title}
      htmlAttributes={{
        lang: languageCode || 'en-US',
      }}
      meta={[
        { name: 'author', content: author },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        // Google News
        { name: 'news_keywords', content: keywords },
        // Facebook
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: siteUrl },
        {
          property: 'og:image',
          content: siteUrl + facebookBanner,
        },
        // Twitter
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: description },
        { name: 'twitter:card', content: 'summary_large_image' },
        {
          name: 'twitter:image:src',
          content: siteUrl + twitterBanner,
        },
        {
          name: 'twitter:creator',
          content: '@' + socialHandles.twitter,
        },
        {
          name: 'twitter:site',
          content: '@' + socialHandles.twitter,
        },
        { name: 'twitter:domain', content: socialHandles.twitter },
      ]}
    />
  );
};
